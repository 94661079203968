<template>
  <div class="row">
    <div class="col-4 text-start">
      <AddUser
        :customerId="value"
        :customerType="type"
        @close="onModalClose"
      ></AddUser>
    </div>
  </div>
  <div v-if="users">
    <table class="table table-sm">
      <thead>
        <tr>
          <td class="text-start">Navn</td>
          <td class="text-start">Email</td>
          <td class="text-start">Virksomhed</td>
          <td>Accepteret betingelser</td>
        </tr>
      </thead>
      <tbody>
        <Row
          v-for="user in users"
          :key="user.id"
          :value="user"
          :customerId="value"
        ></Row>
      </tbody>
    </table>
  </div>
  <div class="bg-light" v-else>Ingen bruger tilknyttet</div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import CustomerUserViewModel from '@/Model/CustomerUserViewModel';
import useCustomers from '../useCustomers';
import AddUser from './addUserModal.vue';
import Row from './customerUserRow.vue';
export default defineComponent({
  components: {
    AddUser,
    Row
  },
  props: {
    value: { type: Number, required: true },
    type: { type: Number, default: 0 }
  },
  setup(props) {
    const { getUsers } = useCustomers();
    const users = ref<Array<CustomerUserViewModel>>();
    const onModalClose = () => {
      getUsers(props.value).then((response: Array<CustomerUserViewModel>) => {
        users.value = response;
      });
    };
    onMounted(() => {
      onModalClose();
    });
    return {
      users,
      onModalClose
    };
  }
});
</script>
