<template>
  <div
    ref="modal"
    class="modal fade"
    :class="{ show: show, 'd-block': show }"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><header>Fjern bruger</header></h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="$emit('update:show', false)"
          ></button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            @click="$emit('update:show', false)"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            Nej
          </button>
          <button
            type="button"
            @click="$emit('ok')"
            class="btn btn-secondary text-white"
          >
            Ja
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="show"
    @click="$emit('update:show', false)"
    class="modal-backdrop fade show"
  ></div>
</template>
<script lang="ts">
import { defineComponent, watch } from 'vue';
export default defineComponent({
  props: {
    show: { type: Boolean, default: false }
  },
  setup(props) {
    const body = document.querySelector('body');
    const modalToggle = () => {
      if (body) {
        props.show
          ? body.classList.add('modal-open')
          : body.classList.remove('modal-open');
      }
    };
    watch(() => props.show, modalToggle);
  }
});
</script>
