
import { defineComponent, watch } from 'vue';
export default defineComponent({
  props: {
    show: { type: Boolean, default: false }
  },
  setup(props) {
    const body = document.querySelector('body');
    const modalToggle = () => {
      if (body) {
        props.show
          ? body.classList.add('modal-open')
          : body.classList.remove('modal-open');
      }
    };
    watch(() => props.show, modalToggle);
  }
});
