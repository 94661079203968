<template>
  <tr v-if="value">
    <td></td>
    <td class="text-start">{{ value.externalRef }}</td>
    <td class="text-start">{{ value.name }}</td>
    <td class="text-start">
      {{ value.address }}
    </td>
    <td class="text-start zipcode">{{ value.zipCode }} {{ value.city }}</td>
    <td>
      <router-link :to="{ name: 'admin_customer', params: { id: value.id } }">
        <i class="bi bi-gear"></i>
      </router-link>
    </td>
  </tr>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import CustomerViewModel from '@/Model/CustomerViewModel';

export default defineComponent({
  props: {
    value: {
      type: Object as PropType<CustomerViewModel>,
      required: true
    }
  }
});
</script>
