<template>
  <button
    type="button"
    class="btn btn-primary"
    data-bs-toggle="modal"
    data-bs-target="#staticBackdrop"
  >
    <i class="bi bi-plus-square"></i>
  </button>
  <!-- Modal -->
  <div
    class="modal fade"
    id="staticBackdrop"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="modalRef"
  >
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Tilføj bruger</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="selectedUser">
            {{ selectedUser.firstname }} {{ selectedUser.lastname }}
            <a @click="removeUser">
              <i class="bi bi-person-x-fill"></i>
            </a>
            <hr />
            <template v-if="customerType == 1">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="all"
                  v-model="selectedType"
                />
                <label class="form-check-label" for="inlineRadio1">
                  Alle lokationer i gruppen
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  value="select"
                  v-model="selectedType"
                />
                <label class="form-check-label" for="inlineRadio2">
                  Vælg lokationer
                </label>
              </div>
              <table
                class="table table-striped"
                v-if="locations && locations.length > 0"
              >
                <thead>
                  <tr>
                    <th>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="allChecked"
                      />
                    </th>
                    <th>Kunde</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="location in locations"
                    :key="location.id"
                    @click="toogleSelect(location.id)"
                  >
                    <td>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :checked="selected(location.id)"
                      />
                    </td>
                    <td>
                      ({{ location.externalRef }}) {{ location.name }},{{
                        location.location
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </div>
          <input
            class="form-control"
            placeholder="Søg bruger"
            v-model="query"
            type="text"
            v-else
          />

          <ul class="list-group">
            <li
              @click="selectUser(user)"
              v-for="user in users"
              :key="user.id"
              class="list-group-item"
            >
              {{ user.firstname }} {{ user.lastname }} ({{ user.email }})
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-bs-dismiss="modal">
            Annuller
          </button>
          <button type="button" class="btn btn-primary" @click="addToCustomer">
            Tilføj
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import useUsers from '../useUsers';
import useCustomers from '../useCustomers';
import UserViewModel from '@/Model/UserViewModel';
import CustomerViewModel from '@/Model/CustomerViewModel';
import { appState } from '@/store';
import { Modal } from 'bootstrap';
export default defineComponent({
  props: {
    customerId: { type: Number, requiered: true },
    customerType: { type: Number, default: 0 }
  },
  setup(props, { emit }) {
    const { search } = useUsers();
    const { get, addUser, getLocations } = useCustomers();
    const modalRef = ref<Element>();
    const query = ref<string>();
    const users = ref<Array<UserViewModel>>();
    const selectedUser = ref<UserViewModel>();
    const customer = ref<CustomerViewModel>();
    const selectedLocations = ref<Array<number>>([]);
    const allChecked = ref<boolean>(false);

    const selectUser = (user: UserViewModel) => {
      selectedUser.value = user;
      users.value = [];
    };
    const locations = ref<Array<CustomerViewModel>>();
    const selectedType = ref<string>('all');
    const close = () => {
      if (modalRef.value) {
        const modal = Modal.getInstance(modalRef.value);
        modal.hide();
        emit('close');
      }
    };
    const selected = (id: number) => {
      return selectedLocations.value.indexOf(id) != -1;
    };
    const toogleSelect = (id: number) => {
      const ids = selectedLocations.value;
      if (selected(id)) {
        const index = ids.indexOf(id);
        ids.splice(index, 1);
      } else {
        ids.push(id);
      }
      selectedLocations.value = ids;
    };
    const addToCustomer = () => {
      const user = selectedUser.value;
      if (user && customer.value) {
        if (props.customerType == 1) {
          if (selectedType.value == 'all') {
            addUser(
              user.id,
              customer.value.id,
              selectedLocations.value,
              selectedType.value
            ).then(() => {
              close();
            }, appState.raiseError);
          } else {
            addUser(
              user.id,
              customer.value.id,
              selectedLocations.value,
              selectedType.value
            ).then(() => {
              close();
            }, appState.raiseError);
          }
        } else {
          addUser(
            user.id,
            customer.value.id,
            [customer.value.id],
            'single'
          ).then(() => {
            close();
          }, appState.raiseError);
        }
      }
    };
    const removeUser = () => {
      selectedUser.value = undefined;
    };
    watch(
      () => allChecked.value,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          let ids: Array<number> = [];
          if (newValue) {
            if (locations.value) {
              ids = locations.value.map((value: CustomerViewModel) => {
                return value.id;
              });
            }
          }
          selectedLocations.value = ids;
        }
      }
    );
    watch(
      () => query.value,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          search(newValue).then((result: Array<UserViewModel>) => {
            users.value = result;
          }, appState.raiseError);
        }
      }
    );
    watch(
      () => selectedType.value,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          if (newValue == 'all') {
            locations.value = [];
          } else {
            if (customer.value)
              getLocations(customer.value.id).then(
                (response: Array<CustomerViewModel>) => {
                  locations.value = response;
                }
              );
          }
        }
      }
    );
    const id = props.customerId ? props.customerId : 0;
    get(id).then((value: CustomerViewModel) => {
      customer.value = value;
    });
    return {
      users,
      query,
      selectUser,
      removeUser,
      selectedUser,
      selectedType,
      locations,
      selected,
      toogleSelect,
      addToCustomer,
      modalRef,
      allChecked
    };
  }
});
</script>
