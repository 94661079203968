<template>
  <tr v-if="value">
    <td
      :data-bs-target="'#u_' + value.id"
      data-bs-toggle="collapse"
      class="text-start"
    >
      {{ value.firstname }} {{ value.lastname }}
    </td>
    <td
      :data-bs-target="'#u_' + value.id"
      data-bs-toggle="collapse"
      class="text-start"
    >
      <i
        class="bi bi-check-circle text-success"
        v-if="value.confirmedEmail"
      ></i>
      <i class="bi bi-dash-circle-fill text-danger" v-else></i>
      {{ value.email }}
    </td>
    <td
      :data-bs-target="'#u_' + value.id"
      data-bs-toggle="collapse"
      class="text-start"
    >
      {{ value.company }}
    </td>
    <td :data-bs-target="'#u_' + value.id" data-bs-toggle="collapse">
      <i
        class="bi bi-check-circle text-success"
        v-if="value.acceptConditions"
      ></i>
      <i class="bi bi-dash-circle-fill text-danger" v-else></i>
    </td>
  </tr>
  <tr>
    <td colspan="12" class="hiddenRow table-light">
      <div class="collapse" :id="'u_' + value.id" ref="collapseRef">
        <template v-if="loading">
          <div class="spinner-border" role="status"></div>
          <br />
          <span>Henter inspektioner...</span>
        </template>
        <template v-else>
          <ul
            class="list-group bg-light"
            v-if="locations && locations.length > 0"
          >
            <li
              class="list-group-item bg-light"
              v-for="customer in locations"
              :key="customer.id"
            >
              <div class="row">
                <div class="col-2 text-start">
                  {{ customer.externalRef }} - {{ customer.type }}
                </div>
                <div class="col-8">
                  <div class="float-start text-start">
                    {{ customer.name }}
                    <br />
                    {{ customer.address }}
                    <br />
                    {{ customer.zipCode }} {{ customer.city }}
                  </div>
                </div>
                <div class="col-2 d-flex align-items-center text-end">
                  <!-- <button class="btn btn-sm btn-secondary text-white">
                    Fjern
                  </button> -->
                </div>
              </div>
            </li>
          </ul>
          <div class="bg-light" v-else>
            <span>Ingen Lokationer</span>
          </div>
        </template>
      </div>
    </td>
  </tr>
</template>
<script lang="ts">
import { defineComponent, onMounted, PropType, ref } from 'vue';
import UserViewModel from '@/Model/UserViewModel';
import useCustomer from '../useCustomers';
import CustomerViewModel from '@/Model/CustomerViewModel';

export default defineComponent({
  props: {
    value: {
      type: Object as PropType<UserViewModel>,
      required: true
    },
    customerId: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const locations = ref<Array<CustomerViewModel> | null>(null);
    const collapseRef = ref();
    const loading = ref<boolean>(false);
    const { getLocationForUser } = useCustomer();
    onMounted(() => {
      if (collapseRef.value) {
        var myCollapsible = collapseRef.value;
        myCollapsible.addEventListener('show.bs.collapse', () => {
          loading.value = true;
          if (
            locations.value == null ||
            (locations.value && locations.value.length == 0)
          ) {
            getLocationForUser(props.value.id, props.customerId).then(
              (response: Array<CustomerViewModel>) => {
                locations.value = response;
                loading.value = false;
              }
            );
          } else {
            loading.value = false;
          }
        });
      }
    });

    return {
      locations,
      collapseRef,
      loading
    };
  }
});
</script>
<style lang="scss" scoped>
.hiddenRow {
  padding: 0;
  border-top: none;
}
</style>
