
import { defineComponent, onMounted, PropType, ref } from 'vue';
import CustomerLocationViewModel from '@/Model/CustomerLocationViewModel';
import CustomerUserViewModel from '@/Model/CustomerUserViewModel';
import UserViewModel from '@/Model/UserViewModel';
import useCustomer from '../useCustomers';
import Modal from '@/components/Modal.vue';

export default defineComponent({
  components: {
    Modal
  },
  props: {
    value: {
      type: Object as PropType<CustomerLocationViewModel>,
      required: true
    }
  },
  setup(props) {
    const { getUsers, removeUser } = useCustomer();
    const users = ref<Array<CustomerUserViewModel> | null>(null);
    const collapseRef = ref();
    const loading = ref<boolean>(false);
    const confirm = ref<boolean>(false);
    const confirmText = ref<string>('');
    const selectedUser = ref<UserViewModel | null>(null);
    const loadUsers = () => {
      getUsers(props.value.id).then(
        (response: Array<CustomerUserViewModel>) => {
          users.value = response;
          loading.value = false;
        }
      );
    };
    const showConfirm = (user: UserViewModel) => {
      selectedUser.value = user;
      confirmText.value = `Vil du fjerne ${selectedUser.value?.firstname} ${selectedUser.value?.lastname} fra ${props.value.name} `;
      confirm.value = true;
    };
    const remove = () => {
      removeUser(selectedUser.value?.id, props.value.id).then(() => {
        confirm.value = false;
        selectedUser.value = null;
        //reload users
        loadUsers();
      });
    };
    onMounted(() => {
      if (collapseRef.value) {
        var myCollapsible = collapseRef.value;
        myCollapsible.addEventListener('show.bs.collapse', () => {
          loading.value = true;
          if (users.value == null || (users.value && users.value.length == 0)) {
            loadUsers();
          } else {
            loading.value = false;
          }
        });
      }
    });
    return {
      collapseRef,
      users,
      loading,
      showConfirm,
      confirm,
      confirmText,
      remove,
      selectedUser
    };
  }
});
