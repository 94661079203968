
import { defineAsyncComponent, defineComponent, ref } from 'vue';
import CustomerViewModel from '@/Model/CustomerViewModel';
import CustomerLocationViewModel from '@/Model/CustomerLocationViewModel';
import useCustomer from '../useCustomers';
export default defineComponent({
  components: {
    CustomerRow: defineAsyncComponent(
      () =>
        import(/* webpackChunkName: "customers" */ './customerLocationRow.vue')
    )
  },
  props: {
    value: { type: Number, required: true }
  },
  setup(props) {
    const locations = ref<Array<CustomerLocationViewModel>>();
    const { getLocations } = useCustomer();
    getLocations(props.value).then((response: Array<CustomerViewModel>) => {
      locations.value = response as Array<CustomerLocationViewModel>;
    });

    return {
      locations
    };
  }
});
