
import { defineComponent, onMounted, PropType, ref } from 'vue';
import UserViewModel from '@/Model/UserViewModel';
import useCustomer from '../useCustomers';
import CustomerViewModel from '@/Model/CustomerViewModel';

export default defineComponent({
  props: {
    value: {
      type: Object as PropType<UserViewModel>,
      required: true
    },
    customerId: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const locations = ref<Array<CustomerViewModel> | null>(null);
    const collapseRef = ref();
    const loading = ref<boolean>(false);
    const { getLocationForUser } = useCustomer();
    onMounted(() => {
      if (collapseRef.value) {
        var myCollapsible = collapseRef.value;
        myCollapsible.addEventListener('show.bs.collapse', () => {
          loading.value = true;
          if (
            locations.value == null ||
            (locations.value && locations.value.length == 0)
          ) {
            getLocationForUser(props.value.id, props.customerId).then(
              (response: Array<CustomerViewModel>) => {
                locations.value = response;
                loading.value = false;
              }
            );
          } else {
            loading.value = false;
          }
        });
      }
    });

    return {
      locations,
      collapseRef,
      loading
    };
  }
});
