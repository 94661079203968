
import {
  defineAsyncComponent,
  defineComponent,
  onMounted,
  PropType,
  ref
} from 'vue';
import CustomerViewModel from '@/Model/CustomerViewModel';
import useCustomer from '../useCustomers';

export default defineComponent({
  components: {
    SingleRow: defineAsyncComponent(
      () =>
        import(/* webpackChunkName: "customers" */ './customerSingleRow.vue')
    )
  },
  props: {
    value: {
      type: Object as PropType<CustomerViewModel>,
      required: true
    }
  },
  setup(props) {
    const locations = ref<Array<CustomerViewModel> | null>(null);
    const collapseRef = ref();
    const loading = ref<boolean>(false);
    const { getLocations } = useCustomer();
    onMounted(() => {
      if (collapseRef.value) {
        var myCollapsible = collapseRef.value;
        myCollapsible.addEventListener('show.bs.collapse', () => {
          loading.value = true;
          if (
            locations.value == null ||
            (locations.value && locations.value.length == 0)
          ) {
            getLocations(props.value.id).then(
              (response: Array<CustomerViewModel>) => {
                locations.value = response;
                loading.value = false;
              }
            );
          } else {
            loading.value = false;
          }
        });
      }
    });

    return {
      locations,
      collapseRef,
      loading
    };
  }
});
