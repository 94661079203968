
import { defineComponent, ref, watch } from 'vue';
import useUsers from '../useUsers';
import useCustomers from '../useCustomers';
import UserViewModel from '@/Model/UserViewModel';
import CustomerViewModel from '@/Model/CustomerViewModel';
import { appState } from '@/store';
import { Modal } from 'bootstrap';
export default defineComponent({
  props: {
    customerId: { type: Number, requiered: true },
    customerType: { type: Number, default: 0 }
  },
  setup(props, { emit }) {
    const { search } = useUsers();
    const { get, addUser, getLocations } = useCustomers();
    const modalRef = ref<Element>();
    const query = ref<string>();
    const users = ref<Array<UserViewModel>>();
    const selectedUser = ref<UserViewModel>();
    const customer = ref<CustomerViewModel>();
    const selectedLocations = ref<Array<number>>([]);
    const allChecked = ref<boolean>(false);

    const selectUser = (user: UserViewModel) => {
      selectedUser.value = user;
      users.value = [];
    };
    const locations = ref<Array<CustomerViewModel>>();
    const selectedType = ref<string>('all');
    const close = () => {
      if (modalRef.value) {
        const modal = Modal.getInstance(modalRef.value);
        modal.hide();
        emit('close');
      }
    };
    const selected = (id: number) => {
      return selectedLocations.value.indexOf(id) != -1;
    };
    const toogleSelect = (id: number) => {
      const ids = selectedLocations.value;
      if (selected(id)) {
        const index = ids.indexOf(id);
        ids.splice(index, 1);
      } else {
        ids.push(id);
      }
      selectedLocations.value = ids;
    };
    const addToCustomer = () => {
      const user = selectedUser.value;
      if (user && customer.value) {
        if (props.customerType == 1) {
          if (selectedType.value == 'all') {
            addUser(
              user.id,
              customer.value.id,
              selectedLocations.value,
              selectedType.value
            ).then(() => {
              close();
            }, appState.raiseError);
          } else {
            addUser(
              user.id,
              customer.value.id,
              selectedLocations.value,
              selectedType.value
            ).then(() => {
              close();
            }, appState.raiseError);
          }
        } else {
          addUser(
            user.id,
            customer.value.id,
            [customer.value.id],
            'single'
          ).then(() => {
            close();
          }, appState.raiseError);
        }
      }
    };
    const removeUser = () => {
      selectedUser.value = undefined;
    };
    watch(
      () => allChecked.value,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          let ids: Array<number> = [];
          if (newValue) {
            if (locations.value) {
              ids = locations.value.map((value: CustomerViewModel) => {
                return value.id;
              });
            }
          }
          selectedLocations.value = ids;
        }
      }
    );
    watch(
      () => query.value,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          search(newValue).then((result: Array<UserViewModel>) => {
            users.value = result;
          }, appState.raiseError);
        }
      }
    );
    watch(
      () => selectedType.value,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          if (newValue == 'all') {
            locations.value = [];
          } else {
            if (customer.value)
              getLocations(customer.value.id).then(
                (response: Array<CustomerViewModel>) => {
                  locations.value = response;
                }
              );
          }
        }
      }
    );
    const id = props.customerId ? props.customerId : 0;
    get(id).then((value: CustomerViewModel) => {
      customer.value = value;
    });
    return {
      users,
      query,
      selectUser,
      removeUser,
      selectedUser,
      selectedType,
      locations,
      selected,
      toogleSelect,
      addToCustomer,
      modalRef,
      allChecked
    };
  }
});
