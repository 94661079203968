<template>
  <tr
    :data-bs-target="'#u_' + value.id"
    data-bs-toggle="collapse"
    class="collapsed"
  >
    <td class="text-start">{{ value.externalRef }}</td>
    <td class="text-start">{{ value.name }}</td>
    <td class="text-start">
      {{ value.address }}
      <br />
      <small>{{ value.location }}</small>
    </td>
    <td class="text-start">{{ value.zipCode }} {{ value.city }}</td>
  </tr>
  <tr>
    <td colspan="12" class="hiddenRow">
      <div class="collapse" :id="'u_' + value.id" ref="collapseRef">
        <div v-if="loading" class="spinner-border" role="status">
          <span>Henter brugere...</span>
        </div>
        <template v-else>
          <table
            class="table table-sm table-light"
            v-if="users && users.length > 0"
          >
            <thead>
              <tr>
                <td class="text-start">Navn</td>
                <td class="text-start">Email</td>
                <td class="text-start">Virksomhed</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in users" :key="user.id">
                <td class="text-start">
                  {{ user.firstname }} {{ user.lastname }}
                </td>
                <td class="text-start">
                  <i
                    class="bi bi-check-circle text-success"
                    v-if="user.confirmedEmail"
                  ></i>
                  <i class="bi bi-dash-circle-fill text-danger" v-else></i>
                  {{ user.email }}
                </td>
                <td class="text-start">{{ user.company }}</td>
                <td>
                  <button
                    type="button"
                    class="btn p-0"
                    @click="showConfirm(user)"
                  >
                    <i class="bi bi-x-square-fill text-secondary"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="bg-light" v-else>
            <span>Ingen brugere</span>
          </div>
          <Modal v-if="selectedUser" v-model:show="confirm" @ok="remove">
            {{ confirmText }}
            <slot name="header">Fjern bruger</slot>
          </Modal>
        </template>
      </div>
    </td>
  </tr>
</template>
<script lang="ts">
import { defineComponent, onMounted, PropType, ref } from 'vue';
import CustomerLocationViewModel from '@/Model/CustomerLocationViewModel';
import CustomerUserViewModel from '@/Model/CustomerUserViewModel';
import UserViewModel from '@/Model/UserViewModel';
import useCustomer from '../useCustomers';
import Modal from '@/components/Modal.vue';

export default defineComponent({
  components: {
    Modal
  },
  props: {
    value: {
      type: Object as PropType<CustomerLocationViewModel>,
      required: true
    }
  },
  setup(props) {
    const { getUsers, removeUser } = useCustomer();
    const users = ref<Array<CustomerUserViewModel> | null>(null);
    const collapseRef = ref();
    const loading = ref<boolean>(false);
    const confirm = ref<boolean>(false);
    const confirmText = ref<string>('');
    const selectedUser = ref<UserViewModel | null>(null);
    const loadUsers = () => {
      getUsers(props.value.id).then(
        (response: Array<CustomerUserViewModel>) => {
          users.value = response;
          loading.value = false;
        }
      );
    };
    const showConfirm = (user: UserViewModel) => {
      selectedUser.value = user;
      confirmText.value = `Vil du fjerne ${selectedUser.value?.firstname} ${selectedUser.value?.lastname} fra ${props.value.name} `;
      confirm.value = true;
    };
    const remove = () => {
      removeUser(selectedUser.value?.id, props.value.id).then(() => {
        confirm.value = false;
        selectedUser.value = null;
        //reload users
        loadUsers();
      });
    };
    onMounted(() => {
      if (collapseRef.value) {
        var myCollapsible = collapseRef.value;
        myCollapsible.addEventListener('show.bs.collapse', () => {
          loading.value = true;
          if (users.value == null || (users.value && users.value.length == 0)) {
            loadUsers();
          } else {
            loading.value = false;
          }
        });
      }
    });
    return {
      collapseRef,
      users,
      loading,
      showConfirm,
      confirm,
      confirmText,
      remove,
      selectedUser
    };
  }
});
</script>
<style lang="scss" scoped>
.hiddenRow {
  padding: 0;
  border-top: none;
}
</style>
