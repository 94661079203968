
import { defineComponent, PropType } from 'vue';
import CustomerViewModel from '@/Model/CustomerViewModel';

export default defineComponent({
  props: {
    value: {
      type: Object as PropType<CustomerViewModel>,
      required: true
    }
  }
});
