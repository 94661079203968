<template>
  <tr v-if="value">
    <td
      :data-bs-target="'#c_' + value.id"
      data-bs-toggle="collapse"
      class="collapsed"
    >
      <i class="bi bi-caret-right"></i>
      <i class="bi bi-caret-down"></i>
    </td>
    <td
      :data-bs-target="'#c_' + value.id"
      data-bs-toggle="collapse"
      class="text-start"
    >
      {{ value.externalRef }}
    </td>
    <td
      class="text-start"
      :data-bs-target="'#c_' + value.id"
      data-bs-toggle="collapse"
    >
      {{ value.name }}
    </td>
    <td
      class="text-start"
      :data-bs-target="'#c_' + value.id"
      data-bs-toggle="collapse"
    >
      {{ value.address }}
    </td>
    <td
      class="text-start"
      :data-bs-target="'#c_' + value.id"
      data-bs-toggle="collapse"
    >
      {{ value.zipCode }} {{ value.city }}
    </td>
    <td>
      <router-link :to="{ name: 'admin_customer', params: { id: value.id } }">
        <i class="bi bi-gear"></i>
      </router-link>
    </td>
  </tr>
  <tr>
    <td colspan="12" class="hiddenRow table-light">
      <div class="collapse ms-3" :id="'c_' + value.id" ref="collapseRef">
        <div v-if="loading" class="spinner-border" role="status">
          <span>Henter brugere...</span>
        </div>
        <template v-else>
          <table
            class="table table-sm table-light subTable"
            v-if="locations && locations.length > 0"
          >
            <colgroup>
              <col style="width: 0px" />
              <col style="width: 105px" class="text-start" />
              <col class="w-25 text-start" />
              <col class="w-50 text-start" />
              <col class="text-start" />
              <col style="width: 20px" />
            </colgroup>
            <!-- <thead>
              <tr>
                <td style="width: 10px"></td>
                <td style="width: 100px" class="text-start">Sags nr.</td>
                <td class="w-25 text-start">Navn</td>
                <td class="w-50 text-start">Adresse</td>
                <td class="text-start">Postnr og by</td>
                <td style="width: 20px"></td>
              </tr>
            </thead> -->
            <tbody>
              <SingleRow
                v-for="customer in locations"
                :key="customer.id"
                :value="customer"
              ></SingleRow>
            </tbody>
          </table>
          <div class="bg-light" v-else>
            <span>Ingen Lokationer</span>
          </div>
        </template>
      </div>
    </td>
  </tr>
</template>
<script lang="ts">
import {
  defineAsyncComponent,
  defineComponent,
  onMounted,
  PropType,
  ref
} from 'vue';
import CustomerViewModel from '@/Model/CustomerViewModel';
import useCustomer from '../useCustomers';

export default defineComponent({
  components: {
    SingleRow: defineAsyncComponent(
      () =>
        import(/* webpackChunkName: "customers" */ './customerSingleRow.vue')
    )
  },
  props: {
    value: {
      type: Object as PropType<CustomerViewModel>,
      required: true
    }
  },
  setup(props) {
    const locations = ref<Array<CustomerViewModel> | null>(null);
    const collapseRef = ref();
    const loading = ref<boolean>(false);
    const { getLocations } = useCustomer();
    onMounted(() => {
      if (collapseRef.value) {
        var myCollapsible = collapseRef.value;
        myCollapsible.addEventListener('show.bs.collapse', () => {
          loading.value = true;
          if (
            locations.value == null ||
            (locations.value && locations.value.length == 0)
          ) {
            getLocations(props.value.id).then(
              (response: Array<CustomerViewModel>) => {
                locations.value = response;
                loading.value = false;
              }
            );
          } else {
            loading.value = false;
          }
        });
      }
    });

    return {
      locations,
      collapseRef,
      loading
    };
  }
});
</script>
<style lang="scss" scoped>
.hiddenRow {
  padding: 0;
  border-top: none;
}

td {
  &.collapsed {
    .bi-caret-right {
      display: block;
    }
    .bi-caret-down {
      display: none;
    }
  }
  .bi-caret-right {
    display: none;
  }
  .bi-caret-down {
    display: block;
  }
}
</style>
