
import { defineComponent, onMounted, ref } from 'vue';
import CustomerUserViewModel from '@/Model/CustomerUserViewModel';
import useCustomers from '../useCustomers';
import AddUser from './addUserModal.vue';
import Row from './customerUserRow.vue';
export default defineComponent({
  components: {
    AddUser,
    Row
  },
  props: {
    value: { type: Number, required: true },
    type: { type: Number, default: 0 }
  },
  setup(props) {
    const { getUsers } = useCustomers();
    const users = ref<Array<CustomerUserViewModel>>();
    const onModalClose = () => {
      getUsers(props.value).then((response: Array<CustomerUserViewModel>) => {
        users.value = response;
      });
    };
    onMounted(() => {
      onModalClose();
    });
    return {
      users,
      onModalClose
    };
  }
});
